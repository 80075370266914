<!--
 * @Author: your name
 * @Date: 2020-12-01 13:21:03
 * @LastEditTime: 2021-05-16 14:19:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\authorizationsuccesstwo\index.vue
-->
<template>
  <div class="all" v-wechat-title="$route.meta.title">
    <navigation :noleft="true" :msg="msg" ></navigation>
    <div class="succlass">
      <img src="../../assets/selected.png" />
      <p>授权成功</p>
      <p id="p1">请及时告知委托方已授权成功</p>
    </div>
    <div class="adclasses">
      <img alt="广告图片" src="../../assets/cd.jpg" />
    </div>
  </div>
</template>
<script>
import base from "../../../util/base"
export default {
  mounted() {
    setTimeout(()=>{
      window.location = base.tourlc;
    },3000)
  },
  data() {
    return {
      msg: "授权成功",
      aa: "noleft",
      color: "red",
      showPivot: false, //是否显示进度条内容
      strokeWidth: ".37rem",
      times: 30, //剩余时间
      percentage: 0, //进度条长度
    };
  },
};
</script>
<style lang="postcss" scoped>
#p1{
  text-align: center;
  font-size: .3rem;
}
.all {
  height: 100%;
  background-color: #ffffff;
}

.adclasses {
  text-align: center;
  margin-top: 0.5rem;
}
.adclasses img {
  width: 6.9rem;
  height: 10.42rem;
  border-radius: 10px;
}
.succlass{
    text-align: center;
}
.succlass img{
    width: 1.4rem;
    height: 1.4rem;
    margin-top: .37rem;
    margin-bottom: .2rem;
}
.succlass p{
    color: #222222;
    font-size: .4rem;
    font-weight: 500;
}
</style>